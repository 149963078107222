import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";

import { customTheme } from "../../theme";
import headerImg from "../../images/header.png";
import Form from "./Form";
import Confirmation from "./Confirmation";

const useStyles = makeStyles({
  img: {
    height: "100%",
    background: "#1c1c1c",
  },
});

const DanceEveningForm = () => {
  const classes = useStyles();
  const [registeredName, setRegisteredName] = useState<string | null>(null);

  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <Box my={4} clone>
        <Container maxWidth="md">
          <Helmet>
            <title>Dance Square – Never miss a chance to dance!</title>
          </Helmet>
          <Card>
            <CardMedia
              component="img"
              alt="Logo"
              height="300"
              image={headerImg}
              title="Nice couple"
              className={classes.img}
            />
            <CardContent>
              <Typography variant="h4" component="h2" gutterBottom>
                Registratie drink
              </Typography>
              {registeredName === null && (
                <Form onReplied={setRegisteredName} />
              )}
              {registeredName !== null && (
                <Confirmation registeredName={registeredName} />
              )}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default DanceEveningForm;
