import { makeStyles } from "@material-ui/core/styles";

import db, { firestore } from "../../firebase/db";

export interface MainFormValue {
  contact: {
    firstName: string;
    lastName: string;
    email: string;
  };
  message: string;
  numberOfPeople: number;
  isParticipating: boolean;
}

export const DEFAULT_FORM_VALUES = {
  contact: {
    firstName: "",
    lastName: "",
    email: "",
  },
  message: "",
  numberOfPeople: 1,
  isParticipating: true,
};

export const SPACING = 2;
export const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: 0,
    marginBottom: 5,
  },
  gridItem: {
    marginTop: (-1 * theme.spacing(SPACING)) / 2,
    marginBottom: (-1 * theme.spacing(SPACING)) / 2,
  },
  separator: {
    height: "1px",
    background: "rgba(0, 0, 0, 0.23)",
    width: "100%",
  },
}));

export const saveFormValues = async (values: MainFormValue): Promise<void> => {
  if (db === null) {
    return;
  }

  await db.collection("registrations").add({
    type: "drink-august-2023",
    contact: values.contact,
    message: values.message,
    createdAt: firestore.Timestamp.fromDate(new Date()),
    numberOfPeople: values.isParticipating ? values.numberOfPeople : 0,
    rejectedAt: null,
  });
};
