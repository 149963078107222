import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Formik, Form as FormikForm } from "formik";
import * as Sentry from "@sentry/react";
import Alert from "@material-ui/lab/Alert";
import TextField from "../TextField";
import Checkbox from "../Checkbox";

import db from "../../firebase/db";
import {
  DEFAULT_FORM_VALUES,
  // eslint-disable-next-line no-unused-vars
  MainFormValue,
  SPACING,
  useStyles,
  saveFormValues,
} from "./constants";

interface FormProps {
  onReplied?: (name: string) => void;
}

const Form = ({ onReplied }: FormProps) => {
  const initialValues = DEFAULT_FORM_VALUES;
  const classes = useStyles();

  const [hasError, setHasError] = useState(false);
  const handleSubmit = (
    values: MainFormValue,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setHasError(false);
    if (db === null) {
      setSubmitting(false);
      return;
    }

    saveFormValues(values)
      .then(() => {
        if (onReplied === undefined) {
          return;
        }
        setSubmitting(false);
        onReplied(values.contact.firstName);
      })
      .catch((error: Error) => {
        setSubmitting(false);
        setHasError(true);
        Sentry.withScope(() => {
          Sentry.captureException(error);
        });
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      formProps={{ autoComplete: "on" }}
    >
      {({ isSubmitting, values }) => (
        <FormikForm>
          <Grid container spacing={SPACING} className={classes.gridContainer}>
            <Grid item xs={12} sm={6} lg={6} className={classes.gridItem}>
              <TextField
                required
                name="contact.firstName"
                label="Voornaam"
                fullWidth
                autoComplete="given-name"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6} className={classes.gridItem}>
              <TextField
                required
                name="contact.lastName"
                label="Achternaam"
                fullWidth
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <TextField
                type="email"
                autoComplete="email"
                required
                name="contact.email"
                label="Email"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <Box mt={3} mb={3} className={classes.separator} />
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
              <Checkbox name="isParticipating" label="Ik wil graag komen" />
            </Grid>
            {values.isParticipating && (
              <Grid item xs={6} className={classes.gridItem}>
                <TextField
                  type="number"
                  name="numberOfPeople"
                  label="Aantal personen"
                  min={1}
                />
              </Grid>
            )}
            <Grid item xs={12} className={classes.gridItem}>
              <Box mt={3} mb={3} className={classes.separator} />
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <TextField
                multiline
                rows={4}
                name="message"
                label="Andere opmerkingen, vertellingen, verzoekjes... kan je hier kwijt"
                disabled={isSubmitting}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <Box mt={2} mb={1}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                >
                  Verzenden
                </Button>
              </Box>
            </Grid>
            {hasError && (
              <Grid item xs={12} className={classes.gridItem}>
                <Box mt={2} mb={1}>
                  <Alert severity="info">
                    Something went wrong. If the problem persists, please report
                    it.
                  </Alert>
                </Box>
              </Grid>
            )}
          </Grid>
        </FormikForm>
      )}
    </Formik>
  );
};

Form.defaultProps = {
  onReplied: undefined,
};

export default Form;
